import type { RemovableRef } from '@vueuse/core';
import { useLocalStorage, StorageSerializers } from '@vueuse/core';
import { defineStore, acceptHMRUpdate } from 'pinia';
import api from '@/utils/api';

interface ServerUser {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  question: string;
  answer: string;
  group: string;
  customer: string;
  state: string;
  white_target: number;
  yellow_target: number;
  dashboard_enabled: number;
  publish_emails: number;
  operator: number;
  schedule_color: string;
}

export class User {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  security_question: string;
  security_answer: string;
  group: string;
  customer: string;
  state: string | null;
  white_target: number | null;
  yellow_target: number | null;
  dashboard_enabled: boolean;
  publish_emails: boolean;
  is_internal: boolean;
  is_operator: boolean;
  schedule_color: string;

  constructor(userData: ServerUser) {
    this.username = userData.username;
    this.email = userData.email;
    this.firstname = userData.firstname;
    this.lastname = userData.lastname;
    this.phone = userData.phone;
    this.security_question = userData.question;
    this.security_answer = userData.answer;
    this.group = userData.group;
    this.customer = userData.customer;
    this.state = userData.state;
    this.white_target = userData.white_target;
    this.yellow_target = userData.yellow_target;
    this.dashboard_enabled = userData.dashboard_enabled === 1;
    this.publish_emails = userData.publish_emails === 1;
    this.is_internal = ['admin', 'internal'].includes(userData.group);
    this.is_operator = userData.operator === 1;
    this.schedule_color = userData.schedule_color;
  }

  // These are the only properties that can be changed by the PUT /user/ endpoint
  static toServer(user: User, password: string = ''): object {
    return {
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone,
      password: password,
      question: user.security_question,
      answer: user.security_answer,
      publish_emails: user.publish_emails ? 1 : 0,
    };
  }
}

export interface AuthStoreState {
  user: RemovableRef<User>;
  returnUrl: string | null;
  fetchingUser: boolean;
  showMapDebugControls: RemovableRef<boolean>;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthStoreState => ({
    returnUrl: null,
    // Need to specify serializer because it can't infer the type from the default value "null"
    user: useLocalStorage<User>('auth/user', null, {
      serializer: StorageSerializers.object,
    }),
    fetchingUser: false,
    showMapDebugControls: useLocalStorage('auth/showMapDebugControls', false),
  }),
  getters: {
    isInternal: (state) => {
      return state.user?.is_internal;
    },
    isAdmin: (state) => {
      return state.user?.group === 'admin';
    },
    isDashboardEnabled: (state) => {
      return state.user?.dashboard_enabled;
    },
  },
  actions: {
    login: async function (username: string, password: string) {
      await api.post(
        '/api/v1/login',
        { username, password },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        },
      );

      await this.getUserData();
      // TODO: check that the user is allowed to go to returnUrl before attempting to navigate there
      window.location.href = this.returnUrl || '/';
    },
    logout: async function () {
      // Cause a full navigation (rather than just using the router) so that our stores get cleared.
      window.location.href = '/api/v1/logout';
    },
    getUserData: async function () {
      this.fetchingUser = true;
      try {
        const { data } = await api.get('/api/v1/user');
        this.user = new User(data);
      } finally {
        this.fetchingUser = false;
      }
    },
    updateUserData: async function (updatedUser: User, password: string = '') {
      await api.put('/api/v1/user/null', User.toServer(updatedUser, password));
      await this.getUserData();
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
