import axios, { type AxiosError } from 'axios';

axios.defaults.timeout = 3000;

const api = axios.create();

/**
 * An interface to encompass the inconsistent types of JSON data the backend sometimes returns on errors.
 * Some examples of the different types it can return:
 *
 *     { result: 'OK' }
 *     { result: 'ERROR', message: 'Customer not found!'}
 *     { result: 'ERR' }
 *     { error: 'Invalid chainage parameter' }
 *     { message: 'No user found' }
 *     { message: 'OK' }
 *     { status: 'error', msg: 'No valid data found' }
 *     ['No valid data found']
 *
 * Sometimes the backend will return an error reponse with a 200 status code, but we're not going to try to handle that
 * at this time. I'd rather fix the backend to properly return an error status code.
 */
export interface ApiError {
  message?: 'OK' | string;
  status?: 'error' | string;
  msg?: string;
  result?: 'OK' | 'ERROR' | 'ERR';
  error?: string;
}

/**
 * Extracts the backend API error message from an Axios error response object.
 * @param error An Error object caught from an Axios request.
 * @returns The message returned by the backend API, if any, or null.
 */
export function getApiErrorMessage(error: AxiosError): string | null {
  const apiError = error.response?.data as ApiError;
  if (apiError) {
    if (apiError.message === 'OK') return null;
    return apiError.message || apiError.msg || apiError.error || null;
  }
  return null;
}

export function globalResponseErrorLogger(
  error: AxiosError,
): Promise<AxiosError> {
  console.error(
    'API Error: url=%s, status=%d, apimessage=%s',
    error.config?.url,
    error.response?.status,
    getApiErrorMessage(error),
  );
  return Promise.reject(error);
}

export function globalLoginRedirect(error: AxiosError): Promise<AxiosError> {
  // Redirect to login page if we get a 401 Unauthorized
  if (
    error.response?.status === 401 &&
    window.location.pathname !== '/login/'
  ) {
    console.log('HTTP 401: Redirecting to login page');
    window.location.href = '/login/';
  }
  // TODO: save return URL so we can redirect back to the page the user was on after logging in
  // TODO: set an error message to display on the login page, or toast
  return Promise.reject(error);
}

api.interceptors.response.use(
  (response) => response,
  globalResponseErrorLogger,
);
api.interceptors.response.use((response) => response, globalLoginRedirect);

export default api;
